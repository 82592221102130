var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row h-100 border-top border-muted rounded-bottom"},[(_vm.showNotes)?_c('div',{staticClass:"d-flex flex-column h-100 border-end border-muted",staticStyle:{"width":"35%","max-width":"170px"},on:{"mouseover":function($event){_vm.notesHover = true},"mouseleave":function($event){_vm.notesHover = false}}},[_c('div',{staticClass:"list-group flex-grow-1 rounded-0 overflow-auto"},_vm._l((_vm.noteList),function(ref){
var id = ref.id;
var note = ref.note;
var title = ref.title;
return _c('button',{key:id,staticClass:"\n          list-group-item list-group-item-action\n          border-0 border-bottom\n          rounded-0\n          d-flex\n          flex-row\n        ",class:{ active: _vm.editingNote === note },attrs:{"type":"button"},on:{"click":function($event){return _vm.editNote(id)},"mouseover":function($event){_vm.noteHover = id},"mouseleave":function($event){_vm.noteHover = false}}},[(title)?_c('div',{staticClass:"flex-grow-1 text-truncate"},[_vm._v(" "+_vm._s(title)+" ")]):_c('div',{staticClass:"flex-grow-1 text-truncate fst-italic",class:_vm.editingNote === note ? 'text-white' : 'text-muted'},[_vm._v(" write here ")]),(
            _vm.getNoteUserCount(id) > 0 && _vm.noteHover !== id && _vm.deleteItem !== id
          )?_c('span',{staticClass:"badge rounded-pill bg-secondary"},[_c('i',{staticClass:"bi",class:_vm.getNoteUserCount(id) > 1 ? 'bi-people-fill' : 'bi-person-fill'}),_vm._v(" "+_vm._s(_vm.getNoteUserCount(id) > 1 ? _vm.getNoteUserCount(id) : "")+" ")]):_vm._e(),(_vm.noteHover === id || _vm.deleteItem === id)?_c('button',{staticClass:"btn btn-sm btn-muted py-0 px-1 rounded-circle",class:{
            'btn-danger': _vm.deleteItem === id,
            'text-white': _vm.editingNote === note,
          },on:{"click":function($event){$event.stopPropagation();return _vm.deleteNote(id, note)}}},[_c('i',{staticClass:"bi bi-trash2"})]):_vm._e()])}),0),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.notesHover || !_vm.noteList || _vm.noteList.length === 0),expression:"notesHover || !noteList || noteList.length === 0"}],staticClass:"btn btn-sm btn-primary p-0",staticStyle:{"border-radius":"0 0 0 5px"},on:{"click":function($event){return _vm.addNote()}}},[_c('i',{staticClass:"bi bi-plus"})])]):_vm._e(),_c('div',{staticClass:"w-100"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editingNote),expression:"editingNote"}],staticClass:"h-100"},[_c('div',{staticClass:"border-0",attrs:{"id":"note-editor"}})]),(!_vm.editingNote)?_c('div',{staticClass:"\n        d-flex\n        justify-content-center\n        align-items-center\n        h-100\n        user-select-none\n      "},[_c('span',{staticClass:"text-uppercase",staticStyle:{"font-size":"0.8em","color":"lightgray"}},[_vm._v(" Select a note ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }