var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('div',{staticClass:"w-100 h-100"},[_c('svg',{staticClass:"w-100 h-100",attrs:{"id":"drawapp-canvas"}})]),(_vm.showPalette)?_c('div',{staticClass:"d-flex flex-row justify-content-center w-100",staticStyle:{"position":"absolute","bottom":"-40px"}},[(!_vm.paletteEdition)?_c('div',{staticClass:"input-group w-auto"},[_vm._l((_vm.colors),function(color){return _c('button',{key:color,staticClass:"btn btn-sm btn-dark w-auto",style:({
          color: color,
          'border-bottom':
            _vm.selectedColor === color ? ("2px solid " + color) : 'none  ',
        }),on:{"click":function($event){_vm.selectedColor = color}}},[_c('i',{staticClass:"bi bi-circle-fill"})])}),_c('button',{staticClass:"btn btn-sm btn-dark w-auto",on:{"click":function($event){_vm.inputPalette = _vm.palette;
          _vm.paletteEdition = true;
          _vm.$nextTick(function () {
            _vm.$el.querySelector('#drawapp-input-palette').focus();
          });}}},[_c('i',{staticClass:"bi bi-hash"})])],2):_vm._e(),(_vm.paletteEdition)?_c('div',{staticClass:"input-group w-75"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputPalette),expression:"inputPalette"}],staticClass:"flex-grow-1 form-control form-control-sm",attrs:{"id":"drawapp-input-palette","type":"text","placeholder":"#00FF00, #BDBDBD..."},domProps:{"value":(_vm.inputPalette)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.savePalette()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.paletteEdition = false}],"input":function($event){if($event.target.composing){ return; }_vm.inputPalette=$event.target.value}}}),_c('button',{staticClass:"btn btn-sm btn-dark",on:{"click":function($event){return _vm.savePalette()}}},[_c('i',{staticClass:"bi bi-arrow-return-left"})]),_c('button',{staticClass:"btn btn-sm btn-dark",on:{"click":function($event){return _vm.restorePalette()}}},[_c('i',{staticClass:"bi bi-arrow-counterclockwise"})]),_c('button',{staticClass:"btn btn-sm btn-dark",on:{"click":function($event){_vm.paletteEdition = false}}},[_c('i',{staticClass:"bi bi-x"})]),_vm._m(0)]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"btn btn-sm btn-dark text-uppercase fw-bold fs-08em",attrs:{"href":"https://www.colourlovers.com/palettes","target":"_blank"}},[_c('i',{staticClass:"bi bi-box-arrow-up-right pe-1"}),_vm._v(" More palettes ")])}]

export { render, staticRenderFns }